import React from "react"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = props => {
  return (
    <BackgroundImage
      className="bg-footer"
      fluid={
        props.bgfooter.aboutPage.footerImage.localFile.childImageSharp.fluid
      }
    ></BackgroundImage>
  )
}

export default BackgroundSection
