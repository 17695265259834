/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Modal from "react-modal"
import { BsCaretRightFill } from "react-icons/bs"
import Obfuscate from "react-obfuscate"
import image from "../images/person_placeholder.jpg"
import Footer from "../components/footer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BgImageFooter from "../components/About/FooterImage"
import {
  FaLinkedin,
  FaTimes,
  FaPhone,
  FaEnvelope,
  FaInfoCircle,
} from "react-icons/fa"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}
const AboutPage = ({ data }) => {
  const [modalIsOpen, setIsOpen] = useState()

  function openModal(id) {
    setIsOpen(id)
  }
  function closeModal() {
    setIsOpen()
  }

  return (
    <Layout>
      <SEO defaultName={data.wpAbout.title} />
      <div className="hero gap-xl">
        <div className="hero-section-about">
          <Img
            fluid={
              data.wpAbout.aboutPage.heroImage.localFile.childImageSharp.fluid
            }
            alt="conference"
          />
          <div className="hero-section-banner">
            <div className="grid-container">
              <h2>{data.wpAbout.aboutPage.heroHeadline}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-x gap-xl">
          <div className="large-6" sx={{ padding: `0rem 3rem 0rem 0rem` }}>
            {!!data.wpAbout.aboutPage.introSubtitle && (
              <h4
                sx={{
                  marginBottom: `10px`,
                  color: `#faa23a`,
                  fontWeight: `600`,
                }}
              >
                {data.wpAbout.aboutPage.introSubtitle}
              </h4>
            )}
            {!!data.wpAbout.aboutPage.introTitle && (
              <h1>{data.wpAbout.aboutPage.introTitle}</h1>
            )}
            {!!data.wpAbout.aboutPage.introText && (
              <p>{data.wpAbout.aboutPage.introText}</p>
            )}
          </div>
          <div className="large-6">
            <Img
              fluid={
                data.wpAbout.aboutPage.introImage.localFile.childImageSharp
                  .fluid
              }
            />
          </div>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-x grid-margin-x gap-xl">
          {!!data.wpAbout.aboutPage.descriptionLeft && (
            <div
              className="large-8 cell"
              dangerouslySetInnerHTML={{
                __html: data.wpAbout.aboutPage.descriptionLeft,
              }}
            ></div>
          )}
          {!!data.wpAbout.aboutPage.iconBox && (
            <div
              className="large-4 cell"
              sx={{
                padding: `50px 20px 20px 20px`,
                background: `rgba(58,93,127, .1)`,
              }}
            >
              <div>
                {data.wpAbout.aboutPage.iconBox.map(e => {
                  return (
                    <div
                      sx={{ display: `grid`, gridTemplateColumns: `2fr 10fr` }}
                    >
                      <BsCaretRightFill size="35" color="#3a5d7f" />
                      <h4 sx={{ fontSize: `20px`, marginBottom: `0px` }}>
                        {e.iconBoxTitle}
                      </h4>
                      <p></p>
                      <p>{e.iconBoxText}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div id="people" className="grid-container gap-xxl">
        <h2>The People</h2>
        <div className="grid-x grid-margin-y grid-margin-x members">
          {data.allWpAbout.nodes[0]?.aboutPage?.people?.map((s, index) => {
            return (
              <div
                key={index}
                className="large-3 medium-6 small-12 cell"
                sx={{ position: `relative` }}
              >
                {s.tinymlMember.map(e => {
                  return (
                    <div key={e?.id}>
                      <div
                        sx={{
                          cursor: `pointer`,
                          width: `250px`,
                          height: `300px`,
                          margin: `0 auto`,
                          position: `relative`,
                          svg: {
                            transition: `200ms`,
                          },
                          ":hover svg": {
                            transform: `scale(1.2)`,
                          },
                        }}
                        onClick={() => openModal(e?.id)}
                      >
                        {e.person.personImage?.localFile?.childImageSharp
                          .fluid ? (
                          <Img
                            fluid={
                              e.person.personImage?.localFile?.childImageSharp
                                .fluid
                            }
                          />
                        ) : (
                          <img src={image} alt="placeholder" />
                        )}
                        <FaInfoCircle
                          size="25"
                          sx={{
                            position: `absolute`,
                            bottom: `5%`,
                            background: `#3a5d7f`,
                            borderRadius: `30px`,
                            left: `5%`,
                            color: `#fff`,
                            p: `1px`,
                          }}
                        />
                      </div>
                      <div sx={{ marginBottom: `25px` }}>
                        {e.person.personName ? (
                          <h4
                            onClick={() => openModal(e?.id)}
                            sx={{
                              cursor: `pointer`,
                              margin: `15px 0 0px 0`,
                              fontSize: `20px`,
                              fontWeight: `600`,
                            }}
                          >
                            {e?.person?.personName}
                          </h4>
                        ) : null}
                        {s?.personTitle ? (
                          <p sx={{ color: `#faa23a`, marginBottom: `0px` }}>
                            {s?.personTitle}
                          </p>
                        ) : null}
                        {e.person.company ? (
                          <p sx={{ color: `#3a5d7f`, marginBottom: `0px` }}>
                            {e.person.company}
                          </p>
                        ) : null}
                        {e.person.alternativeCompany ? (
                          <p sx={{ color: `#3a5d7f`, marginBottom: `15px` }}>
                            {e.person.alternativeCompany}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        {data.allWpAbout.nodes[0]?.aboutPage?.people?.map((d, index) => {
          return (
            <div key={index}>
              {d.tinymlMember.map(row => {
                return (
                  <div key={row?.id}>
                    <Modal
                      isOpen={modalIsOpen === row?.id}
                      onRequestClose={closeModal}
                      closeTimeoutMS={500}
                      style={customStyles}
                      ariaHideApp={false}
                      contentLabel={row?.id}
                    >
                      {row?.person?.personName ? (
                        <h2 sx={{ textAlign: `center`, marginBottom: `10px` }}>
                          {row?.person?.personName}
                        </h2>
                      ) : null}
                      {d?.personTitle ? (
                        <p
                          sx={{
                            color: `#faa23a`,
                            mb: `50px`,
                            textAlign: `center`,
                          }}
                        >
                          {d?.personTitle}
                        </p>
                      ) : (
                        <p sx={{ mb: `50px` }}></p>
                      )}
                      <FaTimes
                        onClick={closeModal}
                        style={{
                          position: `absolute`,
                          right: `15px`,
                          top: `15px`,
                          cursor: `pointer`,
                        }}
                      />
                      <div className="grid-container" onClick={closeModal}>
                        <div className="grid-x grid-margin-x grid-margin-y">
                          <div className="large-6 medium-6 small-12 popup cell">
                            <div sx={{ margin: `0 auto` }}>
                              <div
                                sx={{
                                  width: `300px`,
                                  height: `100%`,
                                  margin: `0 auto`,
                                  textAlign: `center`,
                                }}
                              >
                                {row.person.personImage?.localFile
                                  ?.childImageSharp.fluid ? (
                                  <Img
                                    fluid={
                                      row.person.personImage?.localFile
                                        ?.childImageSharp.fluid
                                    }
                                  />
                                ) : (
                                  <img src={image} />
                                )}
                              </div>
                              <div className="gap-l"></div>
                              <ul
                                onClick="#"
                                sx={{
                                  justifyContent: `center`,
                                  margin: `0`,
                                  display: `flex`,
                                  listStyle: `none`,
                                  gap: `20px`,
                                }}
                              >
                                {row.person.linkedinUrl ? (
                                  <li sx={{ m: [`10px`, `5px`, `0`] }}>
                                    <a
                                      href={row?.person?.linkedinUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <FaLinkedin size={20} />
                                    </a>
                                  </li>
                                ) : null}
                                {row.person.phone ? (
                                  <li sx={{ m: [`10px`, `5px`, `0`] }}>
                                    <Obfuscate href={`tel:${row.person.phone}`}>
                                      <FaPhone size={20} />
                                    </Obfuscate>
                                  </li>
                                ) : null}
                                {row.person.mailAddress ? (
                                  <li sx={{ m: [`10px`, `5px`, `0`] }}>
                                    <Obfuscate
                                      target="__blank"
                                      href={`mailto:${row.person.mailAddress}`}
                                    >
                                      <FaEnvelope size={20} />
                                    </Obfuscate>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                          <div className="large-6 medium-6 small-12 cell">
                            <div>
                              <p sx={{ mb: `0px` }}>{row?.person?.jobTitle}</p>
                              {row.person.alternativeJobTitle ? (
                                <p sx={{ mb: `0px` }}>
                                  {row?.person?.alternativeJobTitle}
                                </p>
                              ) : null}
                              {row.person.company ? (
                                <p sx={{ mb: `0px` }}>
                                  <b>{row?.person?.company}</b>
                                </p>
                              ) : null}
                              {row.person.alternativeCompany ? (
                                <p sx={{ mb: `0px` }}>
                                  <b>{row?.person?.alternativeCompany}</b>
                                </p>
                              ) : null}
                            </div>

                            <p></p>
                            {row.person.bio ? (
                              <p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: row?.person?.bio,
                                  }}
                                ></div>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <Footer />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpAbout {
      nodes {
        title
        aboutPage {
          heroImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          heroHeadline
          introSubtitle
          introTitle
          introText
          introImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          descriptionLeft
          iconBox {
            iconBoxTitle
            iconBoxText
          }
          people {
            personTitle
            tinymlMember {
              ... on WpPerson {
                id
                person {
                  alternativeCompany
                  alternativeJobTitle
                  bio
                  company
                  id
                  jobTitle
                  linkedinUrl
                  mailAddress
                  personName
                  phone
                  personImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          footerImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    wpAbout {
      title
      aboutPage {
        heroImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        heroHeadline
        introSubtitle
        introTitle
        introText
        introImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        descriptionLeft
        iconBox {
          iconBoxTitle
          iconBoxText
        }
        people {
          personTitle
          tinymlMember {
            ... on WpPerson {
              id
              person {
                alternativeCompany
                alternativeJobTitle
                bio
                company
                id
                jobTitle
                linkedinUrl
                mailAddress
                personName
                phone
                personImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        footerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
